<script>
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import rooms from "./rooms";
import {getDropDownElementsByTypeApi} from "@/api/common";
// import SideButtons from '../../../../../../components/side-buttons.vue';
const dt = new Date;
const now = dt.getFullYear()+"-"+(dt.getMonth()+1 < 10 ? "0"+(dt.getMonth()+1): dt.getMonth()+1)+"-"+(dt.getDate()<10 ? "0"+dt.getDate() : dt.getDate()) ;

export default {
    page: {
        title: "Reception ODS",
        meta: [{ name: "description", content: appConfig.description}],
    },
    // import : { fetchSettings },
    components: {/*SideButtons*/rooms},
    props:{
      ODS:{}
    },
    data() {
      return {
      tableData: [],
      title: "Ordre de Service",
      selectedBdv:0,
      diffDays:0,
      restaurants:[],
      nows:now,
      ODS_info: {
        Bdv:'',
        ID_CTT:'',
        contract:'',
        prestataire:'',
        date_ODS:'',
        date_exec_ODS:'',
        period:'',
        comments:''
      },
      Repas:{},
      send:false,
      RepasService:[],
      typeRepas:[],
      have:false,
      keys:'',
      bases:[],
      prests:[],
      contracts:[],
      periods:[],
      filtredRepas:[]

    };
  },
  watch:{
    ODS:{
      handler(val){
        if(val.length > 0 && val[0].id){
          this.ODS_info.id            = val[0].id;
          this.ODS_info.type_ODS      = val[0].type_ODS;
          this.ODS_info.Bdv           = val[0].Bdv;
          this.ODS_info.ID_CTT        = val[0].contract.id;
          this.ODS_info.contract      = val[0].contract;
          this.ODS_info.prestataire   = val[0].service_provider;
          this.ODS_info.date_ODS      = val[0].date_ODS;
          this.ODS_info.date_exec_ODS = val[0].date_exec_ODS;
          // this.ODS_info.period        = val[0].period;
          this.ODS_info.comments      = val[0].comment;
          this.ODS_info.lifebase      = val[0].lifebase;
          this.ODS_info.done          = val[0].done;
          this.ODS_info.items         = val[0].items;
          this.ODS_info.read_at       = val[0].read_at;
          this.RepasService           = Object.keys(val[0].items);
          this.filtredRepas           = val[0].items;
          this.RepasService.forEach(element => {
            this.Repas[element]=undefined;
          });
          this.selectedContract       = val[0].contract;
          this.selectedBdv            = val[0].lifebase;
          this.bases.push(this.selectedBdv);
          this.selectedPrest          = val[0].service_provider;
          this.have                   = true;

          this.reformDat()
        }
        else this.resetData()
      }
    }
  },
  mounted(){
    this.getLifeBases();
    // this.getServiceRepas();
    // this.getlistPeriod();
  },

  methods:{
    getLifeBases(){
      this.$http.post('/base/lifebases/list')
      .then(response => {
          this.bases = response.data.original.list;
      })
      .catch(error => console.log(error))
    },
    onComplete(){

      Swal.fire({
            title: 'Êtes vous sûr ?',
            text: 'de vouloir valider la réalisation de cet ODS ?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2b8a3e',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Annuler',
            confirmButtonText: 'Oui',
          })
          .then((result) => {
            if (result.isConfirmed) {
              console.log(this.Repas);
              this.$http.post('/ods/OdsRestauration/reception',{
                  ods:this.ODS_info, items:this.Repas
              })
              .then(response => {
                  this.send = true;
                  this.$emit('odsvalues',true);
                  Swal.fire("Félicitations!", "Commande Receptionné avec succées", "success");
                  this.resetData();
                  this.send=false;
              
              })
              .catch(error => console.log(error))
            }
          })
      
      
    },
    TodayDate(){
        var td, dt = new Date;
        td = dt.getFullYear()+"-"+(dt.getMonth()+1 < 10 ? "0"+(dt.getMonth()+1): dt.getMonth()+1)+"-"+(dt.getDate()<10 ? "0"+dt.getDate() : dt.getDate()) ;

        return td;
    },
    onValidate(data){
      Swal.fire({
            title: 'Êtes vous sûr ?',
            text: "de vouloir accusé la réception de cet ODS ?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#2b8a3e',
            cancelButtonColor: '#d33',
            cancelButtonText: 'Annuler',
            confirmButtonText: 'Oui',
          }).then((result) => {
            if (result.isConfirmed) {
              this.$http.post('/ods/OdsRestauration/validate',{
              ods:this.ODS_info,status:data
              })
              .then(response => {
                  this.send = true;
                  this.$emit('odsvalues',true);
                  if(data == "Recieved") Swal.fire("Félicitations!","L'accusé de réception de l' " +this.ODS_info.ref+" a été bien envoyé", "success");
                  
                  this.resetData();
                  this.send=false;
                  this.validate=false;
                  this.confirm=false;
              })
              .catch(error => console.log(error))
            }
          })
          
    },
    resetData(){
      this.ODS_info = {
        Bdv:'',
        ID_CTT:'',
        prestataire:'',
        contract:'',
        date_ODS:'',
        date_exec_ODS:'',
        period:'',
        comments:''
      },
      this.RepasService = [],
        this.filtredRepas = [],
        this.have=false
      this.send = true;
    },
    reformDat(){
      // console.log(this.ODS_info.date_exec_ODS.split('-'));
      let d1 = new Date(this.ODS_info.date_exec_ODS.split('-')[0],this.ODS_info.date_exec_ODS.split('-')[1]-1,this.ODS_info.date_exec_ODS.split('-')[2]);
      let d2 = new Date(this.ODS_info.date_ODS.split('-')[0],this.ODS_info.date_ODS.split('-')[1]-1,this.ODS_info.date_ODS.split('-')[2]);
      let diff = (d1.getTime() - d2.getTime()) / (1000*3600*24);

      this.diffDays = diff+" jours";
    }
  }
    
}
</script>

<template>
    <div class="row">
      <div class="col-12">        
              <div class="p-3">
                <div class="">   
                  <div class="row" v-if="ODS_info.id&&ODS_info.date_exec_ODS != nows ">
                    <div class="col">
                      <b-alert variant="warning" show >
                          <i class="mdi mdi-alert-circle"></i> L'enregistrement et la confirmation sera active uniquement le jour de l'execution. Le {{ODS_info.date_exec_ODS_format}}
                      </b-alert>

                      <b-alert variant="danger" show  v-if="ODS[0].extends.length > 0">
                          <i class="mdi mdi-alert-circle"></i> L'éxècution de cette ordre de service a été prolongé, pour plus de détails cliquer ici: <span @click="$emit('extendModal',ODS[0])" style="cursor:pointer;"><i class="fas fa-exclamation-triangle text-warning"></i></span> 
                      </b-alert>
                    </div>
                  </div>              
                      <div class="row">
                            <div class="col-lg-4">
                              <div class="form-group">
                                <label for="formrow-password-input">N° Contrat</label>
                                <input class="form-control" readonly v-model="ODS_info.contract.ref" />
                              </div>
                            </div>
                            <div class="col-lg-4">
                              <div class="form-group">
                                <label for="formrow-inputCity"
                                  >Nom de la Base *</label
                                >
                                <input v-if="ODS_info.lifebase" class="form-control" readonly v-model="ODS_info.lifebase.name"/>
                                <input v-else class="form-control" readonly v-model="ODS_info.Bdv"/>
                              </div>
                            </div>

                            <div class="col-lg-4">
                                <label for="">Prestataire *</label>
                                <input v-if="ODS_info.prestataire" class="form-control" readonly v-model="ODS_info.prestataire.prestname"/>
                                <input v-else class="form-control" readonly v-model="ODS_info.prestataire"/>
                                
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-lg-4">
                                <label for="">Date ODS <i v-b-tooltip.hover.right="'Date où l\'éxecution commence'" class="fas fa-info-circle text-info"></i></label>
                                <input type="date" readonly class="form-control" v-model="ODS_info.date_ODS">
                            </div>
                            <div class="col-lg-4">
                                <label for="">Date execution <i v-b-tooltip.hover.right="'Date où l\'éxecution prendra fin'" class="fas fa-info-circle text-info"></i></label>
                                <input type="date" readonly class="form-control" v-model="ODS_info.date_exec_ODS">
                            </div>
                            <div class="col-lg-4" v-if="diffDays != 0">
                                <label for="">Délai:</label>
                                <input type="text" class="form-control" v-model="diffDays" readonly>
                            </div>
                        </div>

                        <div class="row mt-2">
                          <div class="col-md-12">
                            <div class="form-group">
                              <label for="formrow-inputCity">Commentaires</label>
                              <textarea
                                v-model="ODS_info.comments"
                                class="form-control"
                                cols="30"
                                rows="1"
                                readonly
                              ></textarea>
                            </div>
                          </div>
                        </div>

                      <!-- end row -->
                </div>
                <!-- end card-body -->
          </div>
          <div class="p-3">
            <h4>Prestations</h4>
            <div class="row mt-2" v-for="(roomType,i) in RepasService" :key="i">
              <div class="col" >
                <rooms 
                    :ODS="ODS_info"
                    :key="roomType"
                    :pos="roomType"
                    :service="filtredRepas"
                    :period="diffDays"
                    @getvalues="Repas[`${roomType}`] = $event"
                />
              </div>
            </div>
          </div>
          
          
          <div class="row">
            <div class="col text-right mr-2 mb-3" v-if="ODS_info.id">
              <b-button :disabled="ODS_info.done == 1 || ODS_info.read_at != null" @click="onValidate('Recieved')" variant="info" class="btn-label mr-2">
                  <i class="fas fa-paper-plane label-icon"></i> Accusé de Réception
              </b-button>

              <b-button :disabled="ODS_info.done == 1 || ODS_info.date_exec_ODS != nows || (ODS_info.date_exec_ODS == nows && ODS_info.read_at == null)" @click="onComplete" variant="primary" class="btn-label">
                  <i class="bx bx-check-double label-icon"></i> Enregitrer & Confirmer
              </b-button>
              
            </div>
          </div>
      </div>
    </div>
    <!-- </Layout> -->
</template>
