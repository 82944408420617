<script>
//   import Multiselect from 'vue-multiselect'

const dt = new Date;
const now = dt.getFullYear()+"-"+(dt.getMonth()+1 < 10 ? "0"+(dt.getMonth()+1): dt.getMonth()+1)+"-"+(dt.getDate()<10 ? "0"+dt.getDate() : dt.getDate()) ;
export default {
    components:{},
    props:['service','ODS','send','pos','estimated','disable','planB','period'],
    data(){
        return {
            active:false,
            addPersonel:false,
            fields:[{poste:{poste:"Superviseur",toAsk:false,qte:1},Qte:2,checkQte:2,uom:{designation:'Personne'}}],
            selectedMaterials:[],
            ProductList:[
                engrais=>[
                    {name:"NPK ",unite:"QX",Qte:""},
                    {name:"Urée 46 %",unite:"QX",Qte:""},
                    {name:"TSP",unite:"QX",Qte:""},
                    {name:"Engrais pour pantes d’intérieurs",unite:"L",Qte:""},
                    {name:"Lustrant plante d’intérieur",unite:"B",Qte:""},
                    {name:"Engrais liquide NADI",unite:"L",Qte:""},
                ],
                plantes=>[
                    {name:"Arbre Forestier (âgés de 02 ans)",unite:"P",Qte:""},
                    {name:"Arbre ornementales & arbustes(sujet moyen)",unite:"P",Qte:""},
                    {name:"Arbre ornementales & arbustes (grand sujet 1.5 m)",unite:"P",Qte:""},
                    {name:"Arbre fruitier (âgés de 3ans)",unite:"P",Qte:""},
                    {name:"Palmiers (âgés de 05 ans)",unite:"P",Qte:""},
                    {name:"Gazon en plaque (m2)",unite:"m²",Qte:""},
                ],
                engrais=>[{name:"Arbre ornementales & arbustes(sujet moyen)",unite:"P",Qte:""}],
            ],
            RH:[],
            postes:[],
            EquipmentsList:[],
            EQPListOrdering:[],

        }
    },
    mounted(){
        
        // if(this.service[this.pos][0].quantity > 0){
            switch (this.pos) {
                case 'Prestation de Services':
                        this.service[this.pos].forEach(element => {
                            this.fields = [];
                            
                            if(element.prestation) this.RH.push(element.prestation)
                            this.fields.push({id:element.id,poste:element.prestation,qte:element.quantity,amount:element.amount,uom:element.uom,checkQte:element.quantity})
                            // this.PostesList.push(element.service)
                        })
                        this.emitData();
                    break;
                case 'Acquisition Fournitures':
                    this.service[this.pos].forEach(element => {
                        this.selectedProducts = [];
                        this.selectedProducts.push({id:element.id,product:element.prestation,qte:element.quantity,uom:element.prestation.uom,amount:element.amount,checkQte:element.quantity})
                        // this.ProductsList.push(element.product)
                    })
                    this.emitData();
                    break;
            
                default:
                    break;
            }
    },
    methods:{
        emitData(){
            switch (this.pos) {
                case 'Prestation de Services':
                    this.$emit('getvalues',this.fields)
                    break;
                case 'Acquisition Fournitures':
                    this.$emit('getvalues',this.selectedProducts)
                    break;
            
                default:
                    break;
            }
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return;
            }
        }
    }
    
}
</script>
<template>
    <div class="row">
        <div class="col-12">
            <div class="accordion mb-2" role="tablist">
                <b-card no-body class="mb-1"  v-if="pos == 'Prestation de Services'">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-1 style="background:#e5e9f0; color:black !important; border:none !important;">
                        <div class="row">
                        <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> Prestation de Services</div>
                        <div class="col text-right">
                            <span>Nombre de Prestations demandé: {{fields.length}} </span>
                            <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                            <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                        </div>
                        </div>
                    </b-button>
                    </b-card-header>
                    <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
                        <b-card-body class="shadow-lg">
                            <div class="p-3">

                                <div class="row">
                                    
                                    <div class="col">
                                    <b-card title="Prestation a réalisé">
                                        <table class="table mt-2">
                                            <thead>
                                                <th>Prestation</th>
                                                <th class="text-center">Délai</th>
                                                <th class="text-center">Quantité demandé</th>
                                                <th class="text-center">Unité</th>
                                                <th class="text-right">Quantité réalisé</th>
                                            </thead>

                                            <tr v-for="field in fields" :key="field.poste.id">
                                                <th> {{field.poste.name}} </th>
                                                <th class="text-center"> {{period}} </th>
                                                <td class="text-center"> {{field.qte}} </td>
                                                <td class="text-center" v-if="field.poste.uom"> {{field.poste.uom.designation}}</td>
                                                <td class="text-right" style="width:33%"> <input type="number" :disabled="ODS.read_at == null" :max="field.poste.estimated_qty" class="form-control" min="0" v-model="field.checkQte" @input="emitData()" @keypress="isNumber($event)"></td>
                                            </tr>
                                        </table>
                                        
                                    </b-card>
                                    </div>
                                </div>
                            </div>
                        </b-card-body>
                    </b-collapse>
                </b-card>
                
                <b-card no-body class="mb-1" v-if="pos == 'Acquisition Fournitures'">
                    <b-card-header header-tag="header" class="p-1" role="tab">
                    <b-button block v-b-toggle.accordion-3 style="background:#e5e9f0; color:black !important; border:none !important;">
                        <div class="row">
                            <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> Produits</div>
                            <div class="col text-right">
                                <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                                <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                            </div>
                        </div>
                    </b-button>
                    </b-card-header>
                    <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                        <b-card-body class="shadow-lg">
                            <table class="table mt-2">
                                <thead>
                                    <th>Produit</th>
                                    <th class="text-center">Délai</th>
                                    <th class="text-center">Quantité</th>
                                    <th class="text-center">Unité</th>
                                    <th class="text-right">Confirmer Quantité</th>
                                </thead>

                                <tr v-for="product in selectedProducts" :key="product.product.id">
                                    <th> {{period}} </th>
                                    <th> {{product.product.designation}} </th>
                                    <td class="text-center"> {{product.uom.designation}} </td>
                                    <td class="text-center"> {{product.qte}} </td>
                                    <td class="text-right" style="width:33%"> <input type="number" :disabled="ODS.read_at == null"  class="form-control" min="0" v-model="product.checkQte" @input="emitData()" @keypress="isNumber($event)"></td>
                                </tr>
                            </table>
                        </b-card-body>
                    </b-collapse>
                </b-card>
            </div>
        </div>
    </div>
</template>